<template>
  <SearchLayout
      class="specialist-list"
      :class="{ pending }"
      :filters="filters"
      :pagination="pagination"
      no-items-text="Мы не нашли подходящих специалистов"
  >
    <template v-if="skill" #before>
        <BaseBanner :title-tag="skill ? 'h1' : 'h2'">
            <template v-if="skill" #title>Специалисты <BaseBannerAvatars /><br>по&nbsp;{{ skill.name }}</template>
            <template v-else #title>Подбери подходящего специалиста <BaseBannerAvatars /></template>
        </BaseBanner>
    </template>

    <template v-else #before>
      <BaseBanner>
        <template #title>Найди специалиста для&nbsp;своего проекта</template>
        <template #steps>
          <BaseBannerStep number="1">
            <template #icon><img src="~/assets/img/icons/3d-project.png" alt="Ментор"></template>
            Размещай свой<br>проект
          </BaseBannerStep>
          <BaseBannerStep number="2">
            <template #icon><img src="~/assets/img/icons/3d-letter.png" alt="Календарь"></template>
            Получай<br>отклики
          </BaseBannerStep>
          <BaseBannerStep number="3">
            <template #icon><img src="~/assets/img/icons/3d-users.png" alt="Ментор"></template>
            Выбирай понравившихся<br>специалистов
          </BaseBannerStep>
        </template>
        <template #actions>
          <BaseButton big :to="becomeSpecialistRoute">Стать специалистом</BaseButton>
        </template>
      </BaseBanner>
    </template>

    <template #title>
      <PageTitle :note="pagination.total">Специалисты</PageTitle>
    </template>
    <template #controls="{ isMobile }">
      <SortControl :chip="isMobile" v-if="sortOptions" :options="sortOptions" v-model="filter.sort" />
    </template>
    <template #price="{ active }">
      <FilterPrice
          name="Почасовая ставка"
          ref="slider"
          v-model:from="filter.rate_from"
          v-model:to="filter.rate_to"
          :min="meta.min_rate"
          :max="meta.max_rate"
          @change="onChange"
          :active="active.value"
      />
    </template>
    <template #skills="{ single, active }">
      <FilterSkills
          :items="meta.skills"
          :fetched="fetchedSkills"
          v-model="filter.skills"
          :single="single"
          :active="active.value"
      />
    </template>

    <template #default>
      <SpecialistSearchResultCard
        v-for="specialist in specialists"
        :key="specialist.id"
        :specialist="specialist"
      />
    </template>

    <template #after>
      <BecomeMentor class="become-section" />
      <div class="skills-section" style="margin-top: 20px;">
          <div class="horiz-container">
              <div class="chips">
                  <template v-for="skill in skills.data" :key="skill.id">
                    <Chip v-if="skill.specialist_show" :title="skill.name" :to="{ name: 'specialist-list', params: { slug: skill.slug } }">{{ skill.name }}</Chip>
                  </template>
              </div>
          </div>
      </div>
    </template>
  </SearchLayout>
</template>

<script setup>
import { FilterBuilder } from '~/utils/filters'
import usePagination from '~/composables/usePagination'
import { getAllMyProjects, getSpecialistsMeta, searchProjects, searchSpecialists } from '~/api/projects'
import useIsMounted from '~/composables/useIsMounted'
import {getSkill, getSkills} from "~/api/skills";
import { useBecomeSpecialistRoute } from '~/composables/router'
import Chip from "~/components/common/Chip.vue";

const router = useRouter()
const route = useRoute()

const user = inject('user')

const becomeSpecialistRoute = useBecomeSpecialistRoute()


const { data: skill } = await useAsyncData(async () => route.params?.slug ? getSkill(route.params.slug) : null)

useHead({
  title: skill.value?.specialist_title ?? 'Биржа фриланса Mentoring.digital - каталог специалистов по направлениям'
})

if (skill.value) {
  useServerSeoMeta({
    description: skill.value.specialist_description,
    keywords: skill.value.specialist_keywords
  })
} else {
  useServerSeoMeta({
    description: 'Каталог специалистов по IT-направлениям. Быстрый поиск профессиональных исполнителей под ваши задачи',
  })
}

const { data: skills } = (await useAsyncData(() => getSkills()))

const sortOptions = [
  {
    id: 'new_first',
    name: 'Новые'
  },
  {
    id: 'price_asc',
    name: 'Ставка ниже'
  },
  {
    id: 'price_desc',
    name: 'Ставка выше'
  }
]

const filter = ref({
  rate_from: '',
  rate_to: '',
  skills: skill.value ? [skill.value.id] : [],
  sort: sortOptions[0].id
})

const invitableProjects = useLazyAsyncData(async () => user.value ? await getAllMyProjects() : [], { default: () => null, watch: [user] })
provide('invitableProjects', invitableProjects)

const { data: meta } = await useAsyncData(getSpecialistsMeta)
filter.value.rate_from = meta.value.min_rate
filter.value.rate_to = meta.value.max_rate

;(query => {
  if (query.rate_from) filter.value.rate_from = +query.rate_from
  if (query.rate_to) filter.value.rate_to = +query.rate_to
  if (query['skills[]']) filter.value.skills = [].concat(query['skills[]']).map(s => +s).filter(s => !isNaN(s))
  if (query.sort) filter.value.sort = query.sort
})(route.query)

const slider = ref()
const onChange = (name) => {
  filter.value[name] = Math.max(
      name === 'rate_from' ? meta.value.min_rate : filter.value.rate_from, // min
      Math.min(
          name === 'rate_from' ? filter.value.rate_to : meta.value.max_rate, // max
          filter.value[name] // value
      )
  )

  nextTick(() => slider.value?.updateValue())
}

const filters = new FilterBuilder()
    .price(filter, meta, onChange)
    .skills(filter, () => meta.value.skills)
    .build()

const fetchedSkills = ref(filter.value.skills.slice())
const isMounted = useIsMounted()

const {
  loading: pending,
  pagination,
  page,
  items: specialists,
  load
} = await usePagination(
    (page, perPage) => {
      const data = {
        page,
        'skills[]': filter.value.skills,
        ...Object.assign({}, filter.value, { skills: undefined })
      }
      if (isMounted.value
          || route.query.page
          || filters.some(f => !f.isClear?.value)
          || data.sort !== sortOptions[0].id)
        if (isMounted.value) router.replace({ query: data })

      fetchedSkills.value = filter.value.skills.slice()
      return searchSpecialists({
        ...data,
        per_page: perPage
      })
    },
    ref(5),
    { key: 'specialists' }
)

watchDebounced(filter, () => {
  if (pagination.value.current_page === 1) load()
  else page.value = 1
}, { deep: true, debounce: 800 })
</script>

<style scoped lang="scss">
.chips > * {
  margin-right: 10px;
}
</style>
